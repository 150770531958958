// i18next-extract-mark-ns-start payment-gateway-for-cbd-products

//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

import index1 from 'images/payment_gateway.svg';
import index2 from 'images/best_payment_gateway_rates.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {IndexBackground} from 'components/landings/IndexBackground';
import {ScreenSizes} from '../../../types/responsive';
import {InternalPageLink} from 'components/links/Pages';
import {SupportLink} from 'components/links/Support';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const IndexContent = styled.div`
  padding-top: 30px;
  max-width: 600px;

  @media (max-width: ${ScreenSizes.md}) {
    max-width: 100%;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    padding-top: 10px;
  }
`;

const IndexImage = styled.img`
  position: absolute;
  left: 56%;
  top: 80px;
  width: 540px;
  z-index: 9;

  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.375rem;
    line-height: 3.25rem;
    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const Disclaimer = styled.p`
  font-size: 0.9rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1.476rem;
  strong {
    font-weight: 500;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const Bizum: React.FC<PageProps> = (props) => {
  const {t} = useI18next();
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  return (
    <>
      <SEO
        path="payment-gateway-for-cbd-products"
        title={t('CBD Payment Gateway and Processor')}
        description={t(
          'Get the best CBD payment gateway rates while accepting the widest range of online payment methods. Sell CBD products online with MONEI. Start now >>'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <LargeSectionHeader underline tagName="h1">
                <Trans>
                  Reach more customers with the CBD payment gateway built to help you boost online
                  sales
                </Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Online sales of CBD products have soared in the last few years, but it’s still
                considered a high-risk industry subject to complex local legislations and legal grey
                areas. That’s why choosing the right payment gateway and CBD payment processor for
                your online store is crucial.
              </Trans>
              <Trans parent="p">
                While Stripe, Shopify Payments, and Adyen don’t currently support CBD payments, we
                do.
              </Trans>
              <Trans parent="p">
                Create your account today and integrate with popular e-commerce platforms or your
                custom-built website to accept{' '}
                <InternalPageLink slug="online-payments">online payments</InternalPageLink> for your
                CBD products.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              src={index1}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
              className="hide-on-mobile"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="CBD Payment Gateway"
              title="CBD Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Acquire and retain more customers by accepting CBD payments online</Trans>
              </SectionHeader>
              <Trans parent="p">
                E-commerce is expected to account for nearly{' '}
                <AnchorLink href="https://www.statista.com/statistics/534123/e-commerce-share-of-retail-sales-worldwide/">
                  one-third of global
                </AnchorLink>{' '}
                retail sales by 2024. If you’re not already selling your CBD products online, you’re
                missing out on a growing audience of online shoppers.
              </Trans>
              <Trans parent="p">
                Accepting CBD payments online is just the start. Offering a wide range of{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>{' '}
                for your CBD products will help boost customer satisfaction, reduce abandoned carts,
                and increase your conversion rate.
              </Trans>
              <Trans parent="p">
                That’s why we aggregate many payment methods into a single platform. Collect CBD
                payments via credit or debit card, digital wallets like{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                , and <SupportLink slug="360005144578-PayPal">PayPal</SupportLink>, and Spain’s
                popular P2P payment app,{' '}
                <AnchorLink href="https://monei.com/payment-methods/bizum/">Bizum</AnchorLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Save money on transaction fees for your CBD online store</Trans>
              </SectionHeader>
              <Trans parent="p">
                Get the lowest fixed CBD payment gateway fees and save money as your business grows.
                The more CBD products you sell, the lower your transaction fees will be, thanks to
                our <InternalPageLink slug="pricing">dynamic pricing model</InternalPageLink>.
              </Trans>
              <Disclaimer>
                <Trans parent="strong">
                  Note: 24-hour payment settlements are available once you've become a verified
                  MONEI merchant.
                </Trans>
              </Disclaimer>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index3}
              alt="CBD acquirer/processor"
              title="CBD acquirer processor"
              width={400}
              height={428}
              mobileWidth={180}
            />
          </Section>
          <Section>
            <SectionImage
              src={index1}
              alt="CBD payment gateway"
              title="CBD payment gateway"
              width={600}
              height={600}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Save time with quick e-commerce integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Seamless{' '}
                <AnchorLink href="https://docs.monei.com/docs/">e-commerce integration</AnchorLink>{' '}
                means you’ll be able to sell your CBD products via{' '}
                <InternalPageLink slug="shopify-payment-gateway">Shopify</InternalPageLink>,{' '}
                <InternalPageLink slug="wix-payment-gateway">Wix</InternalPageLink>,{' '}
                <InternalPageLink slug="woocommerce-payment-gateway">WooCommerce</InternalPageLink>,
                <InternalPageLink slug="prestashop-payment-gateway">PrestaShop</InternalPageLink>,
                commercetools, Fabric, Magento, saleor, or custom-built website in minutes.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
    </>
  );
};

export default Bizum;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "payment-gateway-for-cbd-products"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
